import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { MenuItem } from '@lib/rs-ngx';
import { CurrentUser } from '@core/models/user';
import { selectCurrentRsUser } from '@core/state/user/user.selectors';
import { selectDisplayTopBarLinkSwitchRsUser } from '@core/state/app/app.selectors';
import { logout } from '@core/state/user/user.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly appVersion: number;
  public readonly title = 'DIVC';
  public menuItems: MenuItem[] = [];
  public topBarEnvironment: 'dev' | 'tst' | 'acc' | 'prd';
  public topBarCdn?: string;

  private currentUser?: CurrentUser;
  private displayTopBarSwitchItem?: boolean;
  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly store: Store
  ) {
    this.appVersion = environment.version!;
    this.topBarEnvironment = environment.topBarEnvironment;
  }

  public ngOnInit(): void {
    this.topBarCdn = environment.topBarCdn;
    this.setMenuItems();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onRouteChange(url: string): void {
    if (url === '/rs-number' || url === '/') {
      this.menuItems = [];
    } else {
      this.menuItems = [
        {
          url: 'rs-number',
          id: 'rs-number',
          label: 'SWITCH_ACCOUNT',
          display: this.displayTopBarSwitchItem,
          labelSuffix: '(' + this.currentUser?.companyName + ')',
        },
      ];
    }
  }

  public clearUserOnLogout(): void {
    this.store.dispatch(logout());
    localStorage.removeItem('divc_persistent_registrationListFilters');
  }

  private setMenuItems(): void {
    const currentRsUser$ = this.store.select((selectCurrentRsUser));
    const displayTopBarSwitchItem$ = this.store.select((selectDisplayTopBarLinkSwitchRsUser));

    combineLatest([currentRsUser$, displayTopBarSwitchItem$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentRsUser, displayTopBarSwitchItem]) => {
        if (!currentRsUser) {
          this.menuItems = [];
          return;
        }

        this.currentUser = currentRsUser;
        this.displayTopBarSwitchItem = displayTopBarSwitchItem;
      });
  }
}
